import React, { useState, useEffect, useCallback } from 'react';
import { Wallpaper } from '../../components/Wallpaper';
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Footer } from '../../components/Footer';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { justNumbers } from '../../utils/utils';
import { getColorContrast } from '../../utils/helpers'
import { LoadingPage } from '../LoadingPage'
import qs from 'qs';

export const SignedPage = (props) => {
    const { clientThemeSettings, alreadySigned, signerHash, signData } = props;
    const [cookies, setCookie] = useCookies([`qisign_${signerHash}`]);

    const [loading, setLoading] = useState(true)

    const [otherSignatures, setOtherSignatures] = useState([]);
    
    const [currentPage, setCurrentPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0)
    const [hasMoreSignatures, setHasMoreSignatures] = useState(true);

    const OtherSignaturesRequest = useCallback((page) => {
        
        if (signData.signer_data.document_number === null || signData.signer_data.document_number === "") {
            setLoading(false);
            return;
        }

        const queryFilters = {
            page_number: page,
            page_rows: 5,
            status: "on_signature",
            company_key: signData.signer_company_key,
            document_number: justNumbers(signData.signer_data.document_number),
        }
        
        const queryString =  qs
            .stringify(queryFilters, { arrayFormat: 'repeat', encode: false })
            .replaceAll("[", "")
            .replaceAll("]", "");

        const config = {
            headers: {
                Authorization: signData.signer_token,
            },
        };

        axios
            .get(`/signers?${queryString}`, config)
            .then((response) => {

                setOtherSignatures((prevState) => {
                    const existingEnvelopeIds = new Set(prevState.map((signer) => signer.envelope_id));
                    const newSigners = response.data.signers.filter(
                        (signer) => !existingEnvelopeIds.has(signer.envelope_id)
                    );
                    return [...prevState, ...newSigners];
                });

                setMaxPage(response.data.pagination.number_of_pages)

                setHasMoreSignatures(page + 1 < response.data.pagination.number_of_pages);

            })
            .catch(() => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            })

    }, [signData]);

    useEffect(() => {        
        OtherSignaturesRequest(currentPage);
    }, [OtherSignaturesRequest, currentPage]);

    const loadMoreSignatures = () => {
        if (currentPage + 1 < maxPage) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    useEffect(() => {
        setCookie(`qisign_${signerHash}`, cookies, { path: `/s/${signerHash}`, maxAge: 0 });
    }, [setCookie, cookies, signerHash]);

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]}>
            <div className="base-container base-container-width base-container-padding" style={{ display: "flex", backgroundColor: "rgb(245, 245, 242)", minHeight: "40%", alignItems: "center", textAlign: "center" }}>
                <div style={{ margin: "auto" }} className="gray">
                    <CheckCircleIcon className="green-color" style={{ width: "50px", height: "50px", margin: "20px" }} />
                    <h1 className="title-level2" style={{ margin: "auto" }}>{alreadySigned ? "Esse processo já foi finalizado" : "Documentos assinados com sucesso"}</h1>
                </div>
                {otherSignatures.length > 0 && (
                    <div style={{ margin: "auto" }} className="gray">
                        <InfoOutlinedIcon style={{ width: "30px", height: "30px", marginTop: "20px", color: "gray" }} />
                        <h3 className="title-level5" style={{ margin: "0 0 12px 0" }}>Você tem outros documentos para assinar</h3>
                        <div
                            style={{
                                maxHeight: "150px",
                                overflowY: "auto",
                                padding: "10px",
                                border: "0px solid #ddd",
                                borderRadius: "10px",
                            }}
                        >
                            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                                {otherSignatures.map((signer, index) => (
                                    <li
                                        key={index}
                                        style={{
                                            marginBottom: "12px",
                                            padding: "8px 0",
                                            borderTop: "1px solid #ddd",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <strong
                                            style={{
                                                flexShrink: 0,
                                                whiteSpace: "nowrap"
                                            }}
                                        >
                                            Assinar:
                                        </strong>
                                        <a
                                            href={signer.signature_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: "#007bff",
                                                textDecoration: "none",
                                                wordBreak: "break-word",
                                                maxWidth: "100%",
                                                lineHeight: "1.5",
                                                textAlign: "left"
                                            }}
                                        >
                                            {signer.envelope_subject}
                                        </a>
                                    </li>

                                ))}
                            </ul>
                            {hasMoreSignatures && (
                                <button
                                    onClick={loadMoreSignatures}
                                    style={{
                                        marginTop: "10px",
                                        padding: "4px 4px",
                                        border: "none",
                                        backgroundColor: clientThemeSettings["button_color"],
                                        color: getColorContrast(clientThemeSettings["button_color"]),
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    Ver Mais
                                </button>
                            )}
                        </div>
                    </div>
                )}

                <Footer showLink={true} />
            </div>
        </Wallpaper>
    );
};
